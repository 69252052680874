import React, { useState } from 'react';

const Login = ({ handleSignInWithEmailandPassword }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    const callback = (error) => {
      console.log(error)
      if (error) {
        setErrorMessage(error);
      }
    };

    handleSignInWithEmailandPassword({ email, password, callback })
    setIsOpen(false);
  };

  return (
    <div>
      <button onClick={() => setIsOpen(!isOpen)} style={{ width: '180px', height: '45px', background: 'white', marginBottom: '4px'}}>
        Sign in With Email
      </button>
      <div style={{color: 'red', width: '100%'}}>{errorMessage}</div>
      {isOpen && (
        <form>
          <input type="email" value={email} onChange={handleEmailChange} placeholder="Email" style={{ height: '30px', marginRight: '4px'}}/>
          <input type="password" value={password} onChange={handlePasswordChange} placeholder="Password" style={{ height: '30px', marginRight: '4px'}} />
          <button type="button" onClick={handleSubmit} style={{ height: '35px'}}>Submit</button>
        </form>
      )}
    </div>
  );
};

export default Login;
