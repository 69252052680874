// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqUkRqG-It8s2u_WE2yAsi_Jw9JOj8oDs",
  authDomain: "hardware-inno-uat-au.firebaseapp.com",
  projectId: "hardware-inno-uat-au",
  storageBucket: "hardware-inno-uat-au.appspot.com",
  messagingSenderId: "906291544846",
  appId: "1:906291544846:web:53f9863cdc5ffcdb2c4d5f"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);