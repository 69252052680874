import './App.css';
import { useEffect, useState } from "react";
import { storage } from './firebase';
import { ref, getDownloadURL, uploadBytesResumable, listAll, getMetadata } from "firebase/storage";
import { Table, TableBody, TableCell, TableHead, TableRow, FormControl, Select, TextField, MenuItem, Button } from '@material-ui/core';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { gapi } from 'gapi-script';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import Login from './Login';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqUkRqG-It8s2u_WE2yAsi_Jw9JOj8oDs",
  authDomain: "hardware-inno-uat-au.firebaseapp.com",
  projectId: "hardware-inno-uat-au",
  storageBucket: "hardware-inno-uat-au.appspot.com",
  messagingSenderId: "906291544846",
  appId: "1:906291544846:web:53f9863cdc5ffcdb2c4d5f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const useStyles = makeStyles(theme => ({
  tableRow: {
    height: theme.spacing(6),
    border: '1px solid #009879',
  },
  tableCell: {
  },
  headerCell: {
    background: '#009879',
    border: '1px solid #009879',
    color: '#ffffff',
    fontWeight: 'bold',
  },
  form: {
    color: '#009879',
    border: '1px solid #009879',
    padding: '20px',
    borderRadius: '5px',
    marginLeft: '30px',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '800px',
    height: '100%',
  },
}));

function App() {
  const [user, setUser] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('Tooling');
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [progresspercent, setProgresspercent] = useState(0);
  const [sort, setSort] = useState('-updated');
  const [searchTerm, setSearchTerm] = useState('');

  const classes = useStyles();

  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setImgUrl(file.name);
  }

  function handleSignInWithEmailandPassword({ email, password, callback }) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setUser(user);
        // ...
      })
      .catch((error) => {
        const errorMessage = error.message;
        callback(errorMessage);
      });
  }

  const handleSubmit = (e) => {
    const metadata = {
      customMetadata: {
        description: description,
        category: category,
        uploader: user?.name
      }
    }
    if (!file) return;
    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent(progress);
        if (progress === 100) {
          fetchData()
          setFile(null)
          setDescription('')
          setImgUrl(null)
          setProgresspercent(0)
        }
      },
      (error) => {
        alert(error);
      },
      () => {
        // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        //   setImgUrl(downloadURL)
        // });
      }
    );
  }

  const clientId = '906291544846-d837c2vtcok9keokl4e14r1rqppvj5tr.apps.googleusercontent.com';

  useEffect(() => {
    const initClient = () => {
          gapi.client.init({
          clientId: clientId,
          scope: ''
        });
      };
      gapi.load('client:auth2', initClient);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const secret = params.get('secret');
    if (secret === process.env.REACT_APP_SECRET) setUser(secret)
  }, [])

  const onSuccess = (res) => {
      setUser(res.profileObj);
  };
  const onFailure = (err) => {
      setUser(null);
  };
  const logOut = () => {
      setUser(null);
  };

  const listRef = ref(storage, 'files/');

  const fetchData = async () => {
    try {
      const res = await listAll(listRef);
      const items = await Promise.all(
        res.items.map(async (item) => {
          const url = await getDownloadURL(item);
          const metadata = await getMetadata(item);
          return {
            name: item?.name || '',
            url,
            updated: metadata?.updated || '',
            size: metadata?.size || '',
            description: metadata?.customMetadata?.description || '',
            category: metadata?.customMetadata?.category || '',
            uploader: metadata?.customMetadata?.uploader || '',
          };
        })
      );
      setFiles(items);
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const sortData = (files, sort) => {
    if (sort === 'name') {
      return files.slice().sort((a, b) => a.name.localeCompare(b.name));
    }
    if (sort === 'size') {
      return files.slice().sort((a, b) => a.size - b.size);
    }
    if (sort === 'updated') {
      return files.slice().sort((a, b) => dayjs(a.updated).diff(dayjs(b.updated)));
    }
    if (sort === 'uploader') {
      return files.slice().sort((a, b) => a.uploader.localeCompare(b.uploader));
    }
    if (sort === 'category') {
      return files.slice().sort((a, b) => a.category.localeCompare(b.category));
    }
    if (sort === 'description') {
      return files.slice().sort((a, b) => a.category.localeCompare(b.category));
    }
    if (sort === '-name') {
      return files.slice().sort((a, b) => b.name.localeCompare(a.name));
    }
    if (sort === '-size') {
      return files.slice().sort((a, b) => b.size - a.size);
    }
    if (sort === '-updated') {
      return files.slice().sort((a, b) => dayjs(b.updated).diff(dayjs(a.updated)));
    }
    if (sort === '-uploader') {
      return files.slice().sort((a, b) => b.uploader.localeCompare(a.uploader));
    }
    if (sort === '-category') {
      return files.slice().sort((a, b) => b.category.localeCompare(a.category));
    }
    if (sort === '-description') {
      return files.slice().sort((a, b) => b.category.localeCompare(a.category));
    }
    return files;
  };

  const handleSort = (field) => {
    if (field === sort) {
      setSort(`-${field}`);
    } else {
      setSort(field);
    }
  };

  const sortedData = sortData(files, sort);

  const filteredData = sortedData.filter(file => 
    Object.values(file).some(val => 
      typeof val === "string" && val.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className="App">
      {user ? (
        <>

          <div className="header">
            <img src="/BetMake_Logo.png" alt="logo" width={50} height={50} />
            <h1>Hardware Catalog</h1>
            <GoogleLogout clientId={clientId} buttonText="Log out" onLogoutSuccess={logOut} icon={false}/>
          </div>
          <FormControl onSubmit={handleSubmit} className={classes.form}>
            <div>
              <Button
              variant="contained"
              component="label"
              >
                Select File
                <input
                  type="file"
                  hidden
                  onChange={handleImageSelect}
                />
              </Button>
              {imgUrl && <p>{imgUrl}</p>}
            </div>
            <TextField placeholder='Description' variant="outlined" label="Description" onChange={(e) => setDescription(e.target.value)}/>
            <Select labelId="dropdown-label" id="dropdown" defaultValue="Tooling" variant="outlined" onChange={(e) => setCategory(e.target.value)}>
              <MenuItem value="Tooling">Tooling</MenuItem>
              <MenuItem value="Driver">Driver</MenuItem>
              <MenuItem value="Image Setup">Image Setup</MenuItem>
              <MenuItem value="Software">Software</MenuItem>
              <MenuItem value="File">File</MenuItem>
              <MenuItem value="Documentation">Documentation</MenuItem>
            </Select>
            <Button type='submit' variant="contained" onClick={handleSubmit} disabled={!file || progresspercent > 0}>Upload File</Button>
          </FormControl>
        {
          progresspercent > 0 &&
          (<div className='progressBar'>
            <div className='outerbar'>
              <div className='innerbar' style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
            </div>
          </div>)
        }
        
        <TextField
          label="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginTop: "15px", marginLeft: "30px", width: "250px" }}
          variant="outlined"
        />
        <div className="files">
          {
            (
              <Table className={classes.table}>
                <TableHead>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.headerCell} onClick={() => handleSort('name')}>Name</TableCell>
                    <TableCell className={classes.headerCell} onClick={() => handleSort('description')}>Description</TableCell>
                    <TableCell className={classes.headerCell} onClick={() => handleSort('category')}>Category</TableCell>
                    <TableCell className={classes.headerCell} onClick={() => handleSort('updated')}>Updated</TableCell>
                    <TableCell className={classes.headerCell} onClick={() => handleSort('size')}>Size</TableCell>
                    <TableCell className={classes.headerCell} onClick={() => handleSort('uploader')}>Uploader</TableCell>
                    <TableCell className={classes.headerCell}>Download</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item) => (
                    <TableRow key={item.name} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>{item?.name}</TableCell>
                      <TableCell className={classes.tableCell}>{item?.description}</TableCell>
                      <TableCell className={classes.tableCell}>{item?.category}</TableCell>
                      <TableCell className={classes.tableCell}>{dayjs(item?.updated).format('DD-MM-YYYY')}</TableCell>
                      <TableCell className={classes.tableCell}>{formatBytes(item?.size)}</TableCell>
                      <TableCell className={classes.tableCell}>{item?.uploader}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <a href={item.url} download>
                          Download
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )
          }
        </div>
        </>
        
      ): (
        <div className="header">
          <img src="/BetMake_Logo.png" alt="logo" width={50} height={50} />
          <h1>Hardware Catalog</h1>
          <div>
            <Login handleSignInWithEmailandPassword={handleSignInWithEmailandPassword} />
            <GoogleLogin
              clientId={clientId}
              buttonText="Sign in with Google"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={'single_host_origin'}
              isSignedIn={true}
              
            />
          </div>
        </div>
      )}
      
      
    </div>
  );
}
export default App;

const formatBytes = (bytes) => {
  if (bytes === 0) return '0 bytes';

  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
};